<script setup lang="ts">
import type { Breadcrumb } from '#types/system/breadcrumbs'
import type { RouteLocation } from '#vue-router'

const route = useRoute()
const router = useRouter()
const { $pageContext, $i18n } = useNuxtApp()

const breadcrumbs = computed(() => {
  const breadcrumbs
    = typeof route.meta.breadcrumbs === 'function'
      ? route.meta.breadcrumbs({
          ctx: $pageContext.value.value,
          t: $i18n.t,
          route: {
            params: route.params as Record<string, string>,
            query: route.query as Record<string, string>
          }
        })
      : (route.meta.breadcrumbs ?? [])

  return [
    { label: 'navigation.personalSpace' },
    ...breadcrumbs
  ] as Breadcrumb[]
})
</script>

<template>
  <NBreadcrumb v-if="breadcrumbs.length">
    <NBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item?.route"
      :clickable="!!item.route"
      class="flex items-center gap-2"
      @click="
        item.route
          && router.resolve({ name: item.route, params: item?.params ?? {} })
          && router.push({
            name: item.route,
            params: item?.params ?? {},
            query: item?.query ?? {}
          } as RouteLocation)
      "
    >
      <NP
        v-if="item.label"
        class="!m-0"
        :depth="3"
      >
        {{ $t(item.label) }}
      </NP>

      <template #separator>
        <NIcon>
          <Icon
            name="heroicons:chevron-right-16-solid"
            class="text-[17px]"
          />
        </NIcon>
      </template>
    </NBreadcrumbItem>
  </NBreadcrumb>
</template>

<style>
.NBreadcrumb > ul {
  display: flex;
  flex-wrap: wrap;
}
</style>
