<script setup lang="tsx">
const appUi = useAppUi()
</script>

<template>
  <div
    class="border-0 border-b-[5px] border-solid h-26 px-6 md:px-16 flex items-center justify-between border-slots-header-border"
  >
    <NuxtLink :to="{ name: 'dashboard' }">
      <BrandLogo />
    </NuxtLink>
    <div class="flex items-center gap-4">
      <LocaleSelector />
      <MarketplaceLink shop-link="putToSale">
        <NButton
          :color="$themeSlots.value.header.sellButton.background"
          :text-color="$themeSlots.value.header.sellButton.text"
          size="large"
        >
          {{ !appUi.isSmallScreen ? $t("actions.putToMarketplace") : $t("actions.sell") }}
        </NButton>
      </MarketplaceLink>
      <UserMenu />
    </div>
  </div>
</template>
