<script setup lang="ts">
import { AppShell } from '#components'

const route = useRoute()
const appUi = useAppUi()
const userStore = useUserStore()

const { collapsed, menuOptions } = useSidebar()
</script>

<template>
  <AppShell v-model:collapsed="collapsed">
    <template #header>
      <AppHeader />
    </template>
    <template #sider>
      <FloatingSidebar
        v-model:collapsed="collapsed"
        :menu-options="menuOptions"
      >
        <template #header>
          <NEllipsis class="font-bold text-lg">
            {{ $t('labels.hello', { name: userStore.user?.firstName }) }}
            {{ userStore.user?.lastName }}
          </NEllipsis>
          <div class="text-gray-5">
            <p class="m-0">
              {{ userStore.activeOrganisation?.name }}
            </p>
            <!-- <p class="m-0">Entreprise nominative 2</p> -->
          </div>
        </template>
        <template #footer>
          <MarketplaceLink>
            <NButton
              type="info"
              ghost
              block
              class="mt-4"
            >
              {{ $t("navigation.backToMarketplace") }}
            </NButton>
          </MarketplaceLink>
        </template>
      </FloatingSidebar>
    </template>
    <div class="px-5 py-6 flex flex-col gap-4">
      <AppBreadcrumbs v-if="!appUi.isSmallScreen" />
      <Transition
        mode="out-in"
        :name="(route.meta.pageTransition as string) ?? 'slide-fade-reverse'"
      >
        <div
          :key="`${route.name?.toString()}-${userStore.organisationShortId ?? 'DEFAULt'}`"
          class="flex flex-col gap-4 mt-4"
        >
          <!-- <div
            v-if="route.meta.pageTitle"
            class="flex items-center gap-2 -mt-2"
          >
            <MenuHandle
              v-if="appUi.isSmallScreen"
              :open="menuCollapsed"
              @update:open="menuCollapsed = $event"
            />
            <PageTitle />
          </div> -->
          <slot />
        </div>
      </Transition>
    </div>
  </AppShell>
</template>
